import PDPHero from '../../PDPHero/PDPHero';
import Benefits from '../../Benefits/Benefits';
import BenefitsNow from '../../Benefits/BenefitsNow';
import TabsComponent from '../../TabsComponent/TabsComponent';
import Testimonial from '../../Testimonial/Testimonial';
import { useContext } from 'react';
import SiteContext from '../../AppContext';
import FeedingGuide from '../../FeedingGuide/FeedingGuide';
import RelatedProducts from '../../RelatedProducts/RelatedProducts';
import RelatedArticles from '../../RelatedArticles/RelatedArticles';
import ReviewList from '../../Reviews/ReviewList';
import { getAggregate } from '../../../lib/productUtils';
import { getProductSchema } from '../../../lib/Schema';
import Head from 'next/head';
import { useBazaarvoiceStats } from '../../../lib/reviews/bazaarvoice';
import { reviewViewOnlyLocales } from '../../../siteConfig';

import styles from './product.module.scss';

/**
 * React component for Product template
 * @param {object} [props] component props
 * @param {object} [props.fields] props coming from contentful
 * @returns {JSX.Element} Returns Product template react component
 */
export default function Product({ fields }) {
  const { brand, locale } = useContext(SiteContext);
  const bvId = fields.externalIDs?.websiteReviewsId;
  const { data } = useBazaarvoiceStats(bvId);
  const liveReviewStats = data?.Results?.[0]?.ProductStatistics?.ReviewStatistics;
  const totalReviews =
    liveReviewStats?.TotalReviewCount || fields?.bazaarvoiceStats?.TotalReviewCount || 0;
  const hasFeedingGuide = fields.feedingGuideData || fields.feedingGuideSelectText;
  const aggregate =
    liveReviewStats && liveReviewStats.AverageOverallRating
      ? liveReviewStats.AverageOverallRating.toFixed(1)
      : getAggregate({ fields });
  const productSchema = getProductSchema(fields, aggregate);
  let hasRelatedProducts = fields.relatedProducts?.fields != null;
  if (hasRelatedProducts) {
    const productCount = fields.relatedProducts?.fields?.products?.filter(
      (product) =>
        product.fields &&
        (product.fields.availability === 'cat2' || product.fields.availability === 'cat3'),
    ).length;
    if (productCount < 1) hasRelatedProducts = false;
  }

  return (
    <div key={fields.slug}>
      <Head>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(productSchema) }}
        />
      </Head>
      <PDPHero {...fields} aggregate={aggregate} totalReviews={totalReviews} />
      {fields.benefits &&
        (brand === 'now-fresh' ? (
          <BenefitsNow {...fields.benefits?.fields} />
        ) : (
          <Benefits {...fields.benefits?.fields} />
        ))}
      {fields.featuredIngredient && <TabsComponent productFields={fields} />}
      {hasFeedingGuide && <FeedingGuide {...fields} />}
      {fields.testimonialCallout && (
        <Testimonial {...fields.testimonialCallout} hasBackground={false} />
      )}
      {(!reviewViewOnlyLocales.includes(locale) || totalReviews > 0) && (
        <ReviewList {...fields} aggregate={aggregate} totalReviews={totalReviews} />
      )}
      {(hasRelatedProducts || fields.relatedArticles?.fields) && (
        <div className={styles.related}>
          {hasRelatedProducts && (
            <RelatedProducts
              {...fields.relatedProducts}
              productsToShow={4}
              isGridView={false}
              disableAnimation={true}
            />
          )}
          {fields.relatedArticles?.fields && <RelatedArticles {...fields.relatedArticles} />}
        </div>
      )}
    </div>
  );
}
