import { useTranslation } from '../../../lib/hooks';
import styles from './noSearchResults.module.scss';

/**
 * Component for displaying a message when no search results are found.
 * @param {object} props the component props.
 * @param {string} props.query the current search query string.
 * @param {string} props.contentType the content type to filter by.
 * @returns {JSX.Element} the component.
 */
export default function NoSearchResults({ query, contentType = '' }) {
  const { t } = useTranslation();
  const title = query ? t('noResultsTitle', [contentType, query]) : t('noResultsTitleNoQuery');
  const subtitle = query ? t('noResultsSubtitle') : null;

  return (
    <div className={styles.container}>
      <h3 className={styles.headline}>{title}</h3>
      {subtitle && <p className={styles.subheadline}>{subtitle}</p>}
    </div>
  );
}
