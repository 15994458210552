import { useHits } from 'react-instantsearch';
import { useTranslation } from '../../lib/hooks';
import { useRouter } from 'next/router';
import cx from 'classnames';
import CustomHit from '../CustomHit/CustomHit';
import styles from './searchHits.module.scss';
import SearchSuggestions from './SearchSuggestions';
import buildSearchResultLink from './helpers/utils';
import RelatedProducts from '../RelatedProducts/RelatedProducts';

/**
 * Component to render the search hits
 * @param {object} props the props object
 * @param {boolean} props.searchOpen whether the search is open
 * @param {string} props.popularSearchTermsTitle popular search terms title
 * @param {Array} props.popularSearchTerms popular search terms
 * @param {Array} props.popularProducts popular products
 * @param {string} props.locale the locale
 * @param {string} props.slug the slug
 * @param {string} props.query the search query
 * @param {Function} props.closeSearch function to close the search
 * @param {boolean} props.quickSearchEnabled whether quick search is enabled
 * @returns {JSX.Element} the search hits component
 */
export default function SearchHits({
  searchOpen,
  popularSearchTermsTitle,
  popularSearchTerms,
  popularProducts,
  locale,
  slug,
  query,
  closeSearch,
  quickSearchEnabled = false,
}) {
  const { items } = useHits();
  const { t } = useTranslation();
  const router = useRouter();

  if (!searchOpen) return null;

  const handleViewAllClick = () => {
    const searchResultLink = buildSearchResultLink(locale, slug, query);
    router.push(searchResultLink);
    closeSearch();
  };

  return (
    <div className={cx(styles.searchHits, { [styles.open]: searchOpen })}>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          {query && quickSearchEnabled && items.length === 0 && (
            <div className={styles.noResults}>
              <p>{t('common.search.noResults', [query])}</p>
            </div>
          )}
          {items.length === 0 && popularSearchTerms.length > 0 && (
            <SearchSuggestions
              popularSearchTermsTitle={popularSearchTermsTitle}
              popularSearchTerms={popularSearchTerms}
              locale={locale}
              slug={slug}
              closeSearch={closeSearch}
            />
          )}
          {items.map((item) => (
            <CustomHit key={item.objectID} hit={item} textColor="light" />
          ))}
          {items.length === 0 && popularProducts.length > 0 && (
            <RelatedProducts
              fields={{
                title: t('common.navigation.popularProducts'),
                products: popularProducts,
              }}
              className={styles.popularProducts}
              disableAnimation={true}
            />
          )}
        </div>
        {items.length > 0 && (
          <button
            aria-label={t('common.ctas.viewAllResults')}
            className={styles.viewAll}
            onClick={handleViewAllClick}>
            <span>{t('common.ctas.viewAllResults')}</span>
          </button>
        )}
      </div>
    </div>
  );
}
