import Styles from './searchResultSummary.module.scss';
import { useInstantSearch } from 'react-instantsearch';
import { useTranslation } from '../../../lib/hooks';

/**
 * Function to render the search result summary.
 * @returns {JSX.Element} The search result
 */
export default function SearchResultSummary() {
  const { results } = useInstantSearch();
  const { t } = useTranslation();
  const query = results?.query;
  const nbHits = results?.nbHits;
  return (
    <div className={Styles.container}>
      {nbHits > 0 && (
        <>
          <p className={Styles.header}>{t('resultsFor')}</p>
          <p className={Styles.search}>
            {query} ({nbHits})
          </p>
        </>
      )}
    </div>
  );
}
