import { useEffect, useState, useContext } from 'react';
import { useInView } from 'react-intersection-observer';
import { getInternalLinkFromString } from '../../lib/utils';
import { useTranslation } from '../../lib/hooks';
import SiteContext from '../AppContext';
import GenericLink from '../GenericLink/GenericLink';
import { Arrow, LongThinArrow, Video, ExternalLarge } from '../Icons';
import Picture from '../Image/Picture';
import { getImageSize } from '../../lib/imageUtils';
import cx from 'classnames';

import styles from './blogCard.module.scss';

/**
 * @param {object} [props] component props
 * @param {object} [props.articleHeroImage] image content
 * @param {Array} [props.tagLabels] content tags
 * @param {string} [props.title] blog post title
 * @param {string} [props.slug] blog post slug
 * @param {string} [props.externalSlug] blog post slug for external blog posts
 * @param {string} [props.publishDate] publish date for blog posts
 * @param {string} [props.heroVideoEmbedCode] video embed id
 * @param {string} [props.heroVideoEmbedSource] video embed type - youtube or vimeo
 * @param {number} [props.index] index of BlogCard
 * @returns {JSX.Element} react component
 */
export default function BlogCard({
  articleHeroImage,
  tagLabels,
  title,
  slug,
  externalSlug,
  publishDate,
  heroVideoEmbedCode,
  heroVideoEmbedSource,
  index,
}) {
  const { locale, brand } = useContext(SiteContext);
  const { t } = useTranslation();

  const blogGridSize = articleHeroImage?.fields?.file?.details?.image;
  const blogGridImageSizes = {
    xl: getImageSize(375, blogGridSize),
    lg: getImageSize(332, blogGridSize),
    md: getImageSize(423, blogGridSize),
    sm: getImageSize(375, blogGridSize),
    fallbackSize: getImageSize(332, blogGridSize),
  };

  const [animIn, setAnimIn] = useState(false);

  const [blogCardImgRef, inView] = useInView({
    rootMargin: '-100px 0px',
    triggerOnce: true,
  });

  const isExternalBlogPost = !!externalSlug;

  useEffect(() => {
    if (inView) {
      setAnimIn(true);
    }
  }, [inView]);

  const formatDate = (date, locale) => {
    return new Date(date).toLocaleDateString(locale.toLowerCase(), {
      month: 'numeric',
      day: 'numeric',
      year: 'numeric',
    });
  };
  const publishedAtDate = formatDate(publishDate, locale);

  const ArrowIcon =
    brand === 'now-fresh' ? (
      <LongThinArrow className={styles.arrow} />
    ) : (
      <Arrow className={styles.arrow} />
    );

  return (
    <GenericLink
      internalLink={!isExternalBlogPost ? getInternalLinkFromString(slug) : null}
      externalLink={isExternalBlogPost ? externalSlug : null}
      externalIcon={false}
      className={styles.cardContainer}>
      {articleHeroImage?.fields?.file && (
        <div
          className={cx(styles.imgContainer, {
            [styles.animIn]: animIn,
          })}
          aria-hidden="true"
          ref={blogCardImgRef}>
          <Picture
            url={articleHeroImage.fields.file.url}
            imageSizes={blogGridImageSizes}
            quality="50"
            alt={articleHeroImage.fields.description}
            loading={index > 3 ? 'lazy' : 'eager'}
          />
          {heroVideoEmbedCode && heroVideoEmbedSource && <Video className={styles.videoIcon} />}
        </div>
      )}
      {isExternalBlogPost && <p className={styles.date}>{publishedAtDate}</p>}
      <div
        className={cx(styles.tags, {
          [styles.externalBlogTags]: isExternalBlogPost,
        })}>
        {tagLabels && tagLabels.slice(0, 2).map((label, i) => <span key={i}>{label}</span>)}
      </div>
      <div
        className={cx(styles.cardHeadlineContainer, {
          [styles.alignArrowIcon]: isExternalBlogPost,
        })}>
        <p className={styles.cardHeadline}>{title}</p>
        {brand !== 'now-fresh' &&
          (isExternalBlogPost ? <ExternalLarge className={styles.externalArrow} /> : ArrowIcon)}
      </div>
      {brand === 'now-fresh' && (
        <div>
          <p className={styles.readMore}>
            {t('common.ctas.readMore')}
            {isExternalBlogPost ? <ExternalLarge className={styles.externalArrow} /> : ArrowIcon}
          </p>
        </div>
      )}
    </GenericLink>
  );
}
