import { useCurrentRefinements, useInstantSearch } from 'react-instantsearch';
import { useTranslation } from '../../../lib/hooks';
import cx from 'classnames';
import AccessibleButton from '../../AccessibleButton/AccessibleButton';
import styles from './refinementDisplay.module.scss';

/**
 * Component to display the number of active refinements and the number of search results.
 * @param {object} props - the component props
 * @param {string} props.className - the class name to apply to the component
 * @param {Function} props.onToggleFilters - function to handle filter opening
 * @returns {JSX.Element} - The rendered component
 */
export default function RefinementDisplay({ className, onToggleFilters }) {
  const { items } = useCurrentRefinements();
  const { results } = useInstantSearch();
  const { t } = useTranslation();
  const refinementCount = items.reduce((acc, item) => acc + item.refinements.length, 0);
  const facets = results?._rawResults[0].facets || {};

  return (
    <div className={cx(styles.container, className)}>
      {refinementCount > 0 && (
        <div className={styles.desktopRefinements}>
          <span className={styles.refinementCount}>
            {refinementCount > 1 ? t('activeFilters', [refinementCount]) : t('activeFilter')}
          </span>
          <span className={styles.hitCount}>
            {results.nbHits > 1 ? t('results', [results.nbHits]) : t('result')}
          </span>
        </div>
      )}
      {Object.keys(facets).length > 0 && (
        <div className={styles.mobileRefinements}>
          <AccessibleButton
            icon="Expand"
            className={styles.openFiltersBtn}
            ariaLabel={t('openFilters')}
            onClick={() => onToggleFilters(true)}>
            {t('filters') + ` ${refinementCount > 0 ? `(${refinementCount})` : ''}`}
          </AccessibleButton>
        </div>
      )}
    </div>
  );
}
