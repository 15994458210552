import { pick } from './utils';
import { getPetTypeTags } from './productSyntheticTags';
import { resolveBazaarvoiceQueries } from './reviews/bazaarvoice';

/**
 * Return a list of filtered entries based on the set of tags passed
 * @param {Array} entries list of entries to filter on
 * @param {Array} tags the list of tags to filter against
 * @returns {Array} locales from brand
 */
export function getFilteredEntries(entries, tags) {
  if (!tags || tags.length < 1) {
    return entries;
  }
  return entries.filter((entry) => entryMatchesFilters(entry, tags, entry.fields.filterTags));
}

/**
 * Check to see if a entry matches the given filter tags.
 * All tags must be present on a entry to be valid.
 * @param {object} product the product to be validated
 * @param {Array} filters The array of filters to check
 * @param {Array} tags tags from entry
 * @returns {boolean} locales from brand
 */
export function entryMatchesFilters(product, filters, tags) {
  if (!tags && filters && filters.length > 0) {
    return false;
  }
  return filters.every((filter) => {
    return tags
      .map((tag) => tag.fields?.label)
      .includes(filter.fields ? filter.fields.label : filter);
  });
}

/**
 * A method that gets an aggregate rating based on a product
 * @param {object} product Product to evaluate
 * @returns {number} returns aggregate of reviews
 */
export function getAggregate(product) {
  return product?.fields?.bazaarvoiceStats?.['AverageOverallRating']
    ? product.fields.bazaarvoiceStats['AverageOverallRating'].toFixed(1)
    : 0;
}

/**
 * Sort the reviews by date
 * @param {Array} reviews The array of reviews
 * @returns {Array} reviews sorted reverse chronologically
 */
export function sortReviews(reviews) {
  if (reviews) {
    reviews.sort((a, b) => {
      return new Date(b.fields.reviewDate) - new Date(a.fields.reviewDate);
    });
  }
  return reviews;
}

/**
 * removes the placeholder review. This placeholder review is added
 * to block inheritance from other locales.
 * @param {Array} reviews The array of reviews
 * @returns {Array} reviews with the blocking record removed
 */
export function filterReviews(reviews) {
  return reviews
    ? reviews.filter((review) => review.sys.id != process.env.INHERITANCE_BLOCK)
    : reviews;
}

/**
 * Method to get filters based om selected product tags
 * @param {Array<object>} products List of products to get filters from
 * @returns {object} Returns Product template react component
 */
export function getFiltersFromProducts(products) {
  // get all the tags used
  const tagsRaw = products
    .map((product) => product.fields.filterTags)
    .filter((category) => typeof category !== 'undefined')
    .flat(1);

  // sort the tags
  const tagsSorted = tagsRaw.sort((a, b) => {
    const sortOrderA = a.fields?.sortOrder ? a.fields.sortOrder : 0;
    const sortOrderB = b.fields?.sortOrder ? b.fields.sortOrder : 0;
    return sortOrderB - sortOrderA;
  });

  // group into categories
  return tagsSorted.reduce((filters, tag) => {
    if (filters[tag.fields.filterCategory.fields.title]) {
      filters[tag.fields.filterCategory.fields.title].add(tag.fields.label);
    } else {
      filters[tag.fields.filterCategory.fields.title] = new Set();
      filters[tag.fields.filterCategory.fields.title].add(tag.fields.label);
    }
    return filters;
  }, {});
}

/**
 * add custom hard coded filters and tags to pcp
 * @param {Array} productList List of products to format
 * @param {Array} pageData List of products to format
 * @param {string} locale current locale
 * @returns {Array} formatted list
 */
export function addCustomTags(productList, pageData, locale) {
  if (pageData.fields.petType === 'Both') {
    const dogFilterTag = getPetTypeTags(locale, 'Dog');
    const catFilterTag = getPetTypeTags(locale, 'Cat');

    productList.forEach((product) => {
      const petTagToAdd = product.fields.petType === 'Dog' ? dogFilterTag : catFilterTag;
      product.fields.filterTags = [petTagToAdd, ...product.fields.filterTags];
    });
  }

  return productList;
}

/**
 * formats a product list to remove unnecessary data
 * @param {Array} productList List of products to format
 * @returns {Array} formatted list
 */
export function formatProductList(productList) {
  productList.forEach((product) => {
    if (product.fields) {
      product.fields.aggregate = getAggregate(product);
    }
  });

  return pick(productList, [
    'availability',
    'brand',
    'filterCategory',
    'title',
    'description',
    'fields',
    'file',
    'filterTags',
    'label',
    'gridHeadline',
    'iconsWithText',
    'gridDescription',
    'packaging',
    'petType',
    'productImage',
    'productLine',
    'productName',
    'productBadge',
    'productColor',
    'sys',
    'url',
    'description',
    'slug',
    'id',
    'details',
    'image',
    'width',
    'height',
    'aggregate',
    'sortOrder',
    'externalIDs',
    'websiteReviewsId',
    'sku',
    'bazaarvoiceStats',
    'AverageOverallRating',
    'TotalReviewCount',
    'buyOnlineLinks',
    'titleEyebrow',
  ]);
}

/**
 * Formats navigation data by processing any related products
 * @param {object} navigationData - The navigation data object from contentful
 * @param {Array} navigationData.items - Array of navigation items
 * @param {object} navigationData.items[].fields - Fields containing page data
 * @param {string} locale - The locale of the current page
 * @returns {object} The formatted navigation data with processed products
 */
export async function formatNavigationProducts(navigationData, locale) {
  const searchPage = navigationData?.items?.[0]?.fields?.defaultSearchResultPage;
  const products = searchPage?.fields?.relatedProducts?.fields?.products;

  if (!products) {
    return [];
  }

  await resolveBazaarvoiceQueries('navigation', products, locale);
  const formattedProducts = formatProductList(products);
  return formattedProducts;
}
