/**
 * Function to transform Algolia page data into a format that our BlogCard component supports
 * @param {object} page page result from Algolia
 * @returns {object} transformed page data
 */
export default function transformAlgoliaPage(page) {
  return {
    articleHeroImage: {
      fields: {
        file: {
          url: page.image?.url,
          details: {
            image: page.image?.dimensions,
          },
        },
        description: page.image?.description,
      },
    },
    slug: page.slug,
    title: page.title,
    shortDescription: page.shortDescription,
  };
}
