export { default as Layout } from './Layout';
export { default as product } from './Templates/Product/Product';
export { default as homePage } from './Templates/Homepage/Homepage';
export { default as blogPost } from './Templates/BlogPost/BlogPost';
export { default as blogLanding } from './Templates/BlogLanding/BlogLanding';
export { default as testimonialsPage } from './Templates/Testimonial/Testimonial';
export { default as Typography } from './Templates/Typography/Typography';
export { default as whereToBuyPage } from './Templates/WhereToBuy/WhereToBuy';
export { default as categoryLanding } from './Templates/CategoryLanding/CategoryLanding';
export { default as storeLocator } from './Templates/StoreLocator/StoreLocator';
export { default as philosophy } from './Templates/Philosophy/Philosophy';
export { default as freeFormTemplate } from './Templates/FreeForm/FreeForm';
export { default as imageAndBenefits } from './ImageAndBenefits/ImageAndBenefits';
export { default as imageAndText } from './ImageWithText/ImageWithText';
export { default as relatedArticles } from './RelatedArticles/RelatedArticles';
export { default as relatedProducts } from './RelatedProducts/RelatedProducts';
export { default as socialEmbed } from './Inline/SocialEmbed/SocialEmbed';
export { default as testimonialCallout } from './Testimonial/Testimonial';
export { default as textAndCta } from './TransitionCta/TransitionCta';
export { default as benefits } from './BenefitsAndCallout/BenefitsAndCallout';
export { default as form } from './StyledForm/StyledForm';
export { default as faq } from './FAQ/FAQ';
export { default as embeddableMapObject } from './StoreLocatorComponent/StoreLocatorComponent';
export { default as richTextObject } from './RichTextComponent/RichTextComponent';
export { default as headerAndBody } from './HeaderAndBody/HeaderAndBody';
export { default as iconBulletedList } from './IconBulletedList/IconBulletedList';
export { default as linkedImageGrid } from './LinkedImageGrid/LinkedImageGrid';
export { default as linkList } from './LinkList/LinkList';
export { default as searchResultsPage } from './Templates/SearchResult/SearchResult';
