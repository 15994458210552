/**
 * Build search result link
 * @param {string} locale the locale
 * @param {string} slug the slug of the search results page
 * @param {string} query the search query
 * @returns {string} the search result link
 */
export default function buildSearchResultLink(locale = 'en-us', slug = '', query = '') {
  const link = `/${locale.toLowerCase()}/${slug}`;
  const search = encodeURIComponent(query.trim());
  const resultLink = search ? `${link}?s=${search}` : link;
  return resultLink;
}
