import { useContext } from 'react';
import SiteContext from '../AppContext';
import Product from '../ProductGrid/Product';
import NowProductCard from '../RelatedProducts/ProductCards/NowProductCard';
import BlogCard from '../BlogCard/BlogCard';
import transformAlgoliaProduct from '../SiteSearch/helpers/transformProduct';
import transformAlgoliaArticle from '../SiteSearch/helpers/transformArticle';
import transformAlgoliaPage from '../SiteSearch/helpers/transformPage';
import useTranslation from '../../lib/hooks/useTranslation';

/**
 * CustomHit component.
 * @param {object} props - The props object
 * @param {object} props.hit - The hit object from Algolia
 * @param {string} props.textColor - The text color
 * @returns {JSX.Element} - The rendered component
 */
export default function CustomHit({ hit, textColor }) {
  const { brand } = useContext(SiteContext);
  const { t } = useTranslation();

  if (hit.contentType === 'article') {
    const transformedArticle = transformAlgoliaArticle(hit);
    return (
      <div>
        <BlogCard
          articleHeroImage={transformedArticle.articleHeroImage}
          tagLabels={hit.tagLabels}
          title={hit.title}
          slug={hit.slug}
          publishDate={hit.publishDate}
          index={hit.__position}
        />
      </div>
    );
  }

  if (hit.contentType === 'page') {
    const transformedPage = transformAlgoliaPage(hit);
    return (
      <div>
        <BlogCard
          articleHeroImage={transformedPage.articleHeroImage}
          title={transformedPage.title}
          slug={transformedPage.slug}
          index={hit.__position}
        />
      </div>
    );
  }

  const transformedProduct = transformAlgoliaProduct(hit);

  if (brand === 'now-fresh' && hit.contentType === 'product') {
    return (
      <NowProductCard product={transformedProduct} index={hit.__position} isSearchResult={true} />
    );
  }

  return <Product product={transformedProduct} brand={brand} textColor={textColor} t={t} />;
}
