import AccessibleButton from '../AccessibleButton/AccessibleButton';
import RegionSelector from './RegionSelector';
import { useTranslation } from '../../lib/hooks';
import styles from './utilityNav.module.scss';

/**
 * Utility Navigation component
 * @param {object} [props] component props
 * @param {boolean} [props.showLocalesSelector] show locale selector
 * @param {string} [props.locale] current locale
 * @param {object} [props.localesData] object of available locales
 * @param {string} [props.whereToBuyCta] where to buy cta link
 * @param {boolean} [props.showSearchBar] show search bar
 * @returns {JSX.Element} Utility Navigation react component
 */
export default function UtilityNav({
  showLocalesSelector,
  locale,
  localesData,
  whereToBuyCta,
  showSearchBar,
}) {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        {whereToBuyCta && showSearchBar && (
          <AccessibleButton
            className={styles.buyCtaLink}
            type={'link'}
            link={whereToBuyCta}
            theme="dark"
            ariaLabel={t('common.navigation.whereToBuy')}>
            {t('common.navigation.whereToBuy')}
          </AccessibleButton>
        )}
        {whereToBuyCta && showSearchBar && showLocalesSelector && (
          <span className={styles.pipe}>|</span>
        )}
        {showLocalesSelector && (
          <RegionSelector
            id="region-selector-desktop"
            currentLocale={locale}
            locales={localesData}
          />
        )}
      </div>
    </div>
  );
}
