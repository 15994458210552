import { useContext, useRef, useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import useTranslation from '../../lib/hooks/useTranslation';
import Slider from 'react-slick';
import Rating from '../Rating/Rating';
import AccessibleButton from '../AccessibleButton/AccessibleButton';
import GenericLink from '../GenericLink/GenericLink';
import StyledLink from '../StyledLink/StyledLink';
import { getInternalLinkFromString } from '../../lib/utils.js';
import SiteContext from '../AppContext';
import { colors } from '../../lib/constants';
import Picture from '../Image/Picture';
import { getImageSize } from '../../lib/imageUtils';
import cx from 'classnames';

import RelatedProductsNow from './RelatedProductsNow';

import styles from './relatedProducts.module.scss';

/**
 * @param {object} [props] component props
 * @param {number} [props.productsToShow] number of products to show on large and up
 * @param {boolean} [props.isGridView] if is grid view
 * @param {object} [props.fields] related products data
 * @param {object} [props.fields.cta] cta object
 * @param {string} [props.fields.description] description text
 * @param {string} [props.fields.eyebrow] eyebrow text
 * @param {string} [props.fields.title] title text
 * @param {object} [props.fields.products] data for each product
 * @param {boolean} [props.disableAnimation] disable animation
 * @param {string} [props.className] additional class name for the section element
 * @returns {JSX.Element} hero copy react component
 */
export default function RelatedProducts({
  productsToShow = 3,
  isGridView = true,
  fields: { cta, description, eyebrow, title, products },
  className,
  disableAnimation = false,
}) {
  const { brand } = useContext(SiteContext);
  const sliderEl = useRef(null);
  const { t } = useTranslation();
  const colorMap = {
    gather: colors.gather.backgroundCream,
    'go-solutions': colors.goSolutions.backgroundWhite,
    'now-fresh': colors.nowFresh.backgroundTan,
    summit: colors.summit.backgroundWhite,
    petcurean: colors.petcurean.backgroundWhite,
  };
  const color = colorMap[brand];
  const showPackaging = brand === 'now-fresh';
  const useStyledLink = cta?.fields || brand === 'summit' || brand === 'gather';
  const styledLinkThemeMap = {
    gather: 'dark',
    'go-solutions': 'dark',
    'now-fresh': 'light',
    petcurean: 'dark',
    summit: 'dark',
  };

  const ctaButtonType = {
    gather: 'primary',
    'go-solutions': 'secondary',
    'now-fresh': 'secondary',
    petcurean: 'primary',
    summit: 'primary',
  };

  // remove unpublished products that have no field data set or unavailable products
  products = products?.filter(
    (product) =>
      product.fields &&
      (product.fields.availability === 'cat2' || product.fields.availability === 'cat3'),
  );

  const settings = {
    slidesToShow: 1,
    arrows: false,
    dots: brand === 'summit' ? false : true,
    // eslint-disable-next-line max-len
    className: `pdp-related-slick pdp-related-slick--num-prods-${productsToShow} ${
      isGridView ? 'pdp-related-slick--grid' : ''
    }`,
    centerMode: true,
    centerPadding: '24%',
    customPaging: function slickPaging(i) {
      return (
        <button
          onFocus={() => {
            sliderEl.current.slickGoTo(i);
          }}>
          {products[i].fields?.productName}
        </button>
      );
    },
    responsive: [
      {
        breakpoint: 9999,
        settings: 'unslick',
      },
      {
        breakpoint: 1023,
        settings: {},
      },
    ],
  };

  const [animIn, setAnimIn] = useState(false);

  const [relatedProductsImgRef, inView] = useInView({
    rootMargin: '-120px 0px',
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      setAnimIn(true);
    }
  }, [inView]);

  if (brand === 'now-fresh') {
    return (
      <RelatedProductsNow
        fields={{ cta, description, eyebrow, title, products }}
        className={className}
      />
    );
  }

  return (
    <section className={cx(styles.background, className)}>
      <div className={cx(styles.container, { [styles.gridView]: isGridView })}>
        {eyebrow && <p className={styles.eyebrow}>{eyebrow}</p>}
        <h2 className={styles.title}>{title}</h2>
        {description && <p className={styles.description}>{description}</p>}
        <Slider ref={sliderEl} {...settings}>
          {products?.map((product, index) => {
            // Need to create an internalPage object for the AccessibleButton
            const productLink = getInternalLinkFromString(
              product.fields?.slug ? product.fields.slug : '',
            );

            const productImageSize = product?.fields?.productImage?.fields?.file?.details?.image;
            const productImageSizes = {
              sm: getImageSize(359, productImageSize),
              lg: getImageSize(296, productImageSize),
              md: getImageSize(355, productImageSize),
              fallbackSize: getImageSize(296, productImageSize),
            };

            return (
              <GenericLink
                key={index}
                internalLink={productLink}
                className={styles.productWrapper}
                ariaLabel={`${t('common.ctas.learnMoreAbout')} ${product.fields.productName}`}
                ref={relatedProductsImgRef}>
                <div className={styles.slide}>
                  {product.fields && (
                    <>
                      <div
                        className={cx(styles['img-container'], {
                          [styles.animIn]: animIn || disableAnimation,
                        })}>
                        {product.fields.productImage?.fields?.file && (
                          <Picture
                            alt={product.fields.productImage.fields.description}
                            className={styles['prod-img']}
                            url={product.fields.productImage.fields.file.url}
                            imageSizes={productImageSizes}
                            bgColor={color}
                            quality="60"
                            loading="lazy"
                            fallbackFormat="jpg"
                          />
                        )}
                      </div>
                      <div className={styles['prod-rating']}>
                        <Rating rating={product.fields.aggregate} className={styles.rating} />
                      </div>
                      <div className={styles['prod-eyebrow']}>
                        <p>{product.fields.titleEyebrow}</p>
                      </div>
                      <div className={styles['prod-title']}>
                        <h3>{product.fields.productName}</h3>
                      </div>
                      {isGridView && product.fields.packaging && showPackaging && (
                        <div className={styles.prodPackaging}>{product.fields.packaging}</div>
                      )}
                      <div className={styles['prod-btn']}>
                        {/* If there is a separate bottom cta, use a styled link instead */}
                        {useStyledLink ? (
                          <StyledLink
                            type="p2"
                            theme={styledLinkThemeMap[brand]}
                            className={styles['styled-link']}
                            tabIndex="-1"
                            noHref={true}>
                            {t('common.ctas.viewRecipe')}
                          </StyledLink>
                        ) : (
                          <AccessibleButton
                            className={styles.ctaBtn}
                            tabIndex="-1"
                            type="secondary"
                            theme="dark">
                            {t('common.ctas.learnMore')}
                          </AccessibleButton>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </GenericLink>
            );
          })}
        </Slider>
        {cta?.fields && (
          <div className={styles['prod-cta']}>
            {isGridView && cta.fields.linkDescription && (
              <div className={styles.ctaDescription}>{cta.fields.linkDescription}</div>
            )}
            <AccessibleButton type={ctaButtonType[brand]} theme="dark" link={cta}>
              {cta.fields.linkText}
            </AccessibleButton>
          </div>
        )}
      </div>
    </section>
  );
}
