import { useInstantSearch } from 'react-instantsearch';
import { useContext } from 'react';
import { useTranslation } from '../../../lib/hooks';
import cx from 'classnames';
import SiteContext from '../../AppContext';
import AccessibleButton from '../../AccessibleButton/AccessibleButton';
import styles from './searchResultCategories.module.scss';

/**
 * Helper function to get the matching page based on contentType from the searchResultsPageMap.
 * @param {string} facet - The contentType facet.
 * @param {Array} searchResultsPageMap - The array of search result pages.
 * @returns {object|null} - The matching page data or null if no match.
 */
function getMatchingPage(facet, searchResultsPageMap) {
  return searchResultsPageMap.find((page) => page.contentType === facet) || null;
}

/**
 * Prepares the category items for rendering by transforming the raw data into a structured format.
 * @param {object} contentTypeFacets - The facets object containing content type counts.
 * @param {Array} searchResultsPageMap - Array of page configurations for different content types.
 * @param {string} query - The current search query string.
 * @returns {Array<{contentType: string, count: number, pageUrl: string}>}
 * Array of prepared category items.
 */
function prepareCategoryItems(contentTypeFacets, searchResultsPageMap, query) {
  if (!contentTypeFacets) {
    return [];
  }
  return searchResultsPageMap
    .map(({ contentType }) => {
      const count = contentTypeFacets[contentType] || 0;
      const matchingPage = getMatchingPage(contentType, searchResultsPageMap);

      if (!matchingPage) {
        return null;
      }

      return {
        contentType,
        count,
        pageUrl: `${matchingPage.slug}?s=${encodeURIComponent(query)}`,
      };
    })
    .filter(Boolean);
}

/**
 * SearchResultCategories component.
 * @param {object} props - The component props.
 * @param {string} props.activeContentType - The active content type.
 * @param {string} props.className - The class name to apply to the component.
 * @returns {JSX.Element} - The component.
 */
export default function SearchResultCategories({ activeContentType, className }) {
  const { results } = useInstantSearch();
  const siteContext = useContext(SiteContext);
  const { t } = useTranslation();
  const { searchResultsPageMap } = siteContext;
  const contentTypeFacets = results?.facets?.find((facet) => facet.name === 'contentType')?.data;
  const categoryItems = prepareCategoryItems(
    contentTypeFacets,
    searchResultsPageMap,
    results.query,
  );

  return (
    <div className={cx(styles.container, className)}>
      <ul className={styles.tabWrapper}>
        {categoryItems.map(({ contentType, count, pageUrl }) => (
          <li key={contentType} className={styles.tab}>
            <AccessibleButton
              type="link"
              link={{ fields: { internalPage: { fields: { slug: pageUrl } } } }}
              ariaLabel={`View ${contentType} Page`}
              className={cx(styles.tabButton, {
                [styles.active]: activeContentType === contentType,
              })}>
              {t(contentType)} ({count})
            </AccessibleButton>
          </li>
        ))}
      </ul>
    </div>
  );
}
