/**
 * Function to transform Algolia product data into a format that our components support
 * @param {object} product product result from Algolia
 * @returns {object} transformed product data
 */
export default function transformAlgoliaProduct(product) {
  return {
    fields: {
      productImage: {
        fields: {
          file: {
            url: product.image.url,
          },
          description: product.image.description,
        },
      },
      productLine: {
        fields: {
          title: product['Product Line'] ?? '',
        },
      },
      aggregate: product.bazaarvoiceStats?.AverageOverallRating ?? 0,
      productName: product.productName,
      packaging: product.packaging,
      slug: product.slug,
    },
  };
}
