import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useSearchBox, useClearRefinements, useInstantSearch } from 'react-instantsearch';
import { useTranslation } from '../../../lib/hooks';
import styles from './searchBar.module.scss';
import cx from 'classnames';
import { Search, Close, Spinner } from '../../Icons';
import SearchResultSummary from './SearchResultSummary';

/**
 * Custom Search Box component.
 * @param {object} props - The component props.
 * @param {string} props.className - The class name to apply to the component.
 * @param {Function} props.clearOpenFacets - The function to clear open facets.
 * @returns {JSX.Element} - The component.
 */
export default function SearchBar({ className, clearOpenFacets }) {
  const { query, refine } = useSearchBox();
  const { refine: clearRefinements } = useClearRefinements();
  const { status } = useInstantSearch();
  const router = useRouter();
  const [inputValue, setInputValue] = useState(query);
  const { t } = useTranslation();

  // Sync query parameter with search box input on initial load
  useEffect(() => {
    const queryParam = router.query.s || '';
    if (queryParam && queryParam !== query) {
      refine(queryParam);
      setInputValue(queryParam);
    }
  }, [router.query.s, query, refine]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    clearRefinements();
    clearOpenFacets();
    refine(inputValue);
    router.push(
      {
        pathname: router.pathname,
        query: { ...router.query, s: inputValue },
      },
      undefined,
      { shallow: true },
    );
  };

  const handleReset = (e) => {
    e.preventDefault();
    // First clear the input and refinements
    setInputValue('');
    clearRefinements();
    clearOpenFacets();

    // Then update the URL which will trigger the search
    const newQuery = { ...router.query };
    delete newQuery.s;

    // Use router.replace instead of push to avoid adding to history
    router
      .replace(
        {
          pathname: router.pathname,
          query: newQuery,
        },
        undefined,
        { shallow: true },
      )
      .then(() => {
        // Finally, ensure the search is refined
        refine('');
      });
  };

  return (
    <div className={cx(styles.searchContainer, className)}>
      <form className={styles.searchForm} onSubmit={handleSubmit}>
        <button type="submit" className={styles.searchSubmit} title={t('common.ctas.submitSearch')}>
          <Search />
        </button>
        <input
          className={styles.searchInput}
          type="search"
          aria-label="Search"
          autoComplete="off"
          autoCapitalize="off"
          autoCorrect="off"
          spellCheck="false"
          maxLength="512"
          value={inputValue}
          onChange={handleInputChange}
          placeholder={t('common.ctas.search')}
        />
        <span hidden={status !== 'loading'} className={styles.searchLoading}>
          <Spinner />
        </span>
        {inputValue && (
          <button
            type="button" // Changed from type="reset" to type="button"
            className={styles.searchReset}
            onClick={handleReset}
            aria-label={t('common.ctas.clearSearch')}>
            <Close />
          </button>
        )}
      </form>
      <SearchResultSummary />
    </div>
  );
}
