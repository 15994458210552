import { useInView } from 'react-intersection-observer';
import { useEffect, useState } from 'react';
import useTranslation from '../../../lib/hooks/useTranslation';
import cx from 'classnames';
import Picture from '../../Image/Picture';
import Rating from '../../Rating/Rating';
import GenericLink from '../../GenericLink/GenericLink';
import StyledLink from '../../StyledLink/StyledLink';
import { getImageSize } from '../../../lib/imageUtils';
import { getInternalLinkFromString } from '../../../lib/utils.js';
import styles from './nowProductCard.module.scss';

/**
 * @param {object} [props] component props
 * @param {object} [props.product] product data
 * @param {number} [props.index] index of the product
 * @param {boolean} [props.isSearchResult] flag to determine if the product is a search result
 * @returns {JSX.Element} react component for NowProductCard
 */
export default function NowProductCard({ product, index, isSearchResult }) {
  const productLink = getInternalLinkFromString(product.fields?.slug ? product.fields.slug : '');
  const productImageSize = product?.fields?.productImage?.fields?.file?.details?.image;
  const productImageSizes = {
    sm: getImageSize(898, productImageSize),
    lg: getImageSize(740, productImageSize),
    md: getImageSize(888, productImageSize),
    fallbackSize: getImageSize(898, productImageSize),
  };

  const { t } = useTranslation();
  const [animIn, setAnimIn] = useState(false);
  const [relatedProductsImgRef, inView] = useInView({
    rootMargin: '-120px 0px',
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      setAnimIn(true);
    }
  }, [inView]);

  return (
    <GenericLink
      key={index}
      internalLink={productLink}
      className={cx(styles.productWrapper, {
        [styles.rightAlign]: index % 2 !== 0,
        [styles.singleColumn]: isSearchResult,
      })}
      ariaLabel={`${t('common.ctas.learnMoreAbout')} ${product.fields.productName}`}
      ref={relatedProductsImgRef}>
      <div
        className={cx(styles['img-container'], {
          [styles.animIn]: animIn,
          [styles.singleColumn]: isSearchResult,
        })}>
        {product.fields.productImage?.fields?.file && (
          <Picture
            alt={product.fields.productImage.fields.description}
            className={styles['prod-img']}
            url={product.fields.productImage.fields.file.url}
            imageSizes={productImageSizes}
            quality="100"
            loading="lazy"
            fallbackFormat="jpg"
          />
        )}
      </div>
      <div
        className={cx(styles.infoContainer, {
          [styles.singleColumn]: isSearchResult,
        })}>
        <div className={styles['prod-rating']}>
          <Rating rating={product.fields.aggregate} className={styles.rating} />
        </div>
        <div className={styles['prod-eyebrow']}>
          <p>{product.fields.titleEyebrow}</p>
        </div>
        <div className={styles['prod-title']}>
          <h3>{product.fields.productName}</h3>
        </div>
        <div className={styles['prod-btn']}>
          <StyledLink
            type="p2"
            theme="dark"
            className={styles['styled-link']}
            tabIndex="-1"
            noHref={true}>
            {t('common.ctas.viewRecipe')}
          </StyledLink>
        </div>
      </div>
    </GenericLink>
  );
}
