import { useEffect, useRef, useState } from 'react';
import { fromEvent, interval } from 'rxjs';
import { debounce, distinctUntilChanged, map } from 'rxjs/operators';
import useTranslation from '../../lib/hooks/useTranslation';
import BlogCard from '../BlogCard/BlogCard';
import Pagination from '../Pagination/Pagination';

import styles from './blogGrid.module.scss';

/**
 * @returns {number} number of results according to viewport
 */
function getResultPerPage() {
  const resultsPerViewportWidth = {
    small: 8,
    medium: 10,
    large: 12,
  };
  if (window.innerWidth < 720) {
    return resultsPerViewportWidth.small;
  } else if (window.innerWidth >= 720 && window.innerWidth < 1024) {
    return resultsPerViewportWidth.medium;
  } else {
    return resultsPerViewportWidth.large;
  }
}

/**
 * @param {object} [props] component props
 * @param {Array<object>} [props.blogPosts] blog posts
 * @param {object} [props.state] blog landing state
 * @param {Function} [props.dispatch] blog landing dispatcher
 * @returns {JSX.Element} react component
 */
export default function BlogGrid({ blogPosts, state, dispatch }) {
  const { t } = useTranslation();
  const blogGridEl = useRef();
  const [resultsPerPage, setResultsPerPage] = useState(0);
  const firstResultFromPage = state.currentPage * resultsPerPage;
  const lastResultFromPage = state.currentPage * resultsPerPage + resultsPerPage;

  useEffect(() => {
    setResultsPerPage(getResultPerPage());
    const resizeSubscription = fromEvent(window, 'resize')
      .pipe(
        debounce(() => interval(50)),
        map(() => getResultPerPage()),
        distinctUntilChanged(),
      )
      .subscribe((resultsPerPage) => {
        dispatch({ type: 'set-current-page', currentPage: 0 });
        setResultsPerPage(resultsPerPage);
      });

    return () => {
      resizeSubscription.unsubscribe();
    };
  }, [dispatch]);

  const blogPostsToShow = blogPosts ? blogPosts.slice(firstResultFromPage, lastResultFromPage) : [];

  return (
    <section className={styles.container} ref={blogGridEl}>
      <div className={styles.results}>
        {blogPostsToShow.length > 0 && (
          <span>
            {t('pagination', [
              blogPosts.length > 0 ? firstResultFromPage + 1 : 0,
              lastResultFromPage < blogPosts.length ? lastResultFromPage : blogPosts.length,
              blogPosts.length,
            ])}
          </span>
        )}
      </div>
      {blogPostsToShow.map((blogPost, i) => (
        <BlogCard {...blogPost.fields} index={i} key={i} />
      ))}
      {blogPosts.length > resultsPerPage && (
        <Pagination
          resultPerPage={resultsPerPage}
          className={styles.pagination}
          totalResults={blogPosts.length}
          page={state.currentPage}
          setPage={(callback) => {
            const newPage = typeof callback === 'function' ? callback(state.currentPage) : callback;
            dispatch({ type: 'set-current-page', currentPage: newPage });
          }}
          resultsEl={blogGridEl}
        />
      )}
    </section>
  );
}
