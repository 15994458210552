/**
 * Function to transform Algolia article data into a format that our Article component supports
 * @param {object} article article result from Algolia
 * @returns {object} transformed article data
 */
export default function transformAlgoliaArticle(article) {
  return {
    articleHeroImage: {
      fields: {
        file: {
          url: article.image?.url,
          details: {
            image: article.image?.dimensions,
          },
        },
        description: article.image?.description,
      },
    },
    slug: article.slug,
    tagLabels: article.tagLabels || [],
    title: article.title,
    publishDate: article.publishDate,
  };
}
