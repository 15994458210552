import {
  useInstantSearch,
  RefinementList,
  useCurrentRefinements,
  useClearRefinements,
} from 'react-instantsearch';
import cx from 'classnames';
import { useRef, useEffect, forwardRef } from 'react';
import { useTranslation } from '../../../lib/hooks';
import styles from './facetFilters.module.scss';
import { Caret } from '../../Icons';
import AccessibleButton from '../../AccessibleButton/AccessibleButton';

/**
 * FacetFilters Component
 * Dynamically renders refinement lists for all available facets.
 * @param {object} props - The props object
 * @param {string} props.className - The class name to apply to the component.
 * @param {string} props.activeContentType - The active content type.
 * @param {object} props.openFacets - The state of open facets.
 * @param {Function} props.onFacetToggle - The function to toggle facets.
 * @param {Function} props.onClose - The function to close the filters.
 * @param {boolean} props.isOpen - The state of whether the filters are open.
 * @returns {JSX.Element} - A list of dynamically rendered refinement options.
 */
const FacetFilters = forwardRef(
  ({ className, activeContentType, openFacets, onFacetToggle, onClose, isOpen }, ref) => {
    const { results } = useInstantSearch();
    const { items } = useCurrentRefinements();
    const { refine: clearRefinements } = useClearRefinements();
    const refinementRefs = useRef({});
    const { t } = useTranslation();
    const refinementCount = items.reduce((acc, item) => acc + item.refinements.length, 0);

    useEffect(() => {
      const currentObservers = {};

      const getFullHeight = (element) => {
        const child = element.firstElementChild;
        if (!child) return 0;
        const childRect = child.getBoundingClientRect();
        const computedStyle = window.getComputedStyle(child);
        const marginTop = parseFloat(computedStyle.marginTop);
        const marginBottom = parseFloat(computedStyle.marginBottom);
        return childRect.height + marginTop + marginBottom;
      };

      // Create resize observers for each facet
      Object.entries(openFacets).forEach(([facetName]) => {
        const element = refinementRefs.current[facetName];
        if (element) {
          const observer = new ResizeObserver(() => {
            if (openFacets[facetName]) {
              element.style.height = `${getFullHeight(element)}px`;
            }
          });
          observer.observe(element.firstElementChild);
          currentObservers[facetName] = observer;
        }
      });

      // Update height for each refinement list
      Object.entries(openFacets).forEach(([facetName, isOpen]) => {
        const element = refinementRefs.current[facetName];
        if (element) {
          element.style.height = isOpen ? `${getFullHeight(element)}px` : '0';
        }
      });

      // Cleanup observers on unmount or when effect re-runs
      return () => {
        Object.values(currentObservers).forEach((observer) => observer.disconnect());
      };
    }, [openFacets]);

    /**
     * Extracts available facets from the search results.
     * https://github.com/algolia/instantsearch/issues/6202
     * @type {object} - An object where keys are facet names and values are facet data.
     */
    const facets = results?._rawResults[0].facets || {};
    // remove the contentType facet
    delete facets.contentType;
    delete facets.productType;

    // Get facet order from renderingContent
    const facetOrder = results?.renderingContent?.facetOrdering?.facets?.order || [];

    // Sort facet entries based on the order array
    const sortedFacetEntries = Object.entries(facets).sort((a, b) => {
      const indexA = facetOrder.indexOf(a[0]);
      const indexB = facetOrder.indexOf(b[0]);

      // If both facets are not in the order array, maintain their original order
      if (indexA === -1 && indexB === -1) return 0;
      // If only one facet is not in the order array, put it at the end
      if (indexA === -1) return 1;
      if (indexB === -1) return -1;
      // Sort based on the order array
      return indexA - indexB;
    });

    return (
      <div
        className={cx(styles.container, className, {
          [styles.isOpen]: isOpen,
        })}
        ref={ref}>
        <div className={styles.interactiveHeadlineContainer}>
          <AccessibleButton
            className={styles.interactiveHeadline}
            ariaLabel={t('closeFilters')}
            onClick={onClose}>
            <Caret className={styles.caret} />
            {t('filterResults')}
          </AccessibleButton>
          <AccessibleButton
            type="link"
            ariaLabel={t('clearFilters')}
            onClick={clearRefinements}
            className={cx(styles.clearCta, {
              [styles.visible]: refinementCount > 0,
            })}>
            {t('clear')}
          </AccessibleButton>
        </div>
        <div className={styles.filtersScrollContainer}>
          <div className={styles.titleContainer}>
            <h3 className={styles.title}>{t(activeContentType)}</h3>
            <AccessibleButton
              type="link"
              ariaLabel={t('clearFilters')}
              onClick={clearRefinements}
              className={cx(styles.clearCtaDesktop, {
                [styles.visible]: refinementCount > 0,
              })}>
              {t('clear')}
            </AccessibleButton>
          </div>
          {sortedFacetEntries.map(([facetName]) => (
            <div key={facetName} className={styles.filterGroup}>
              <AccessibleButton
                className={cx(styles.categoryTitle, { [styles.open]: openFacets[facetName] })}
                ariaExpanded={openFacets[facetName]}
                onClick={() => onFacetToggle(facetName)}>
                {t(facetName)}
                <Caret />
              </AccessibleButton>
              <div
                ref={(el) => (refinementRefs.current[facetName] = el)}
                className={cx(styles.refinementContainer, {
                  [styles.open]: openFacets[facetName],
                })}>
                <RefinementList
                  attribute={facetName}
                  classNames={{
                    root: styles.refinementList,
                    list: styles.filters,
                    item: styles.filter,
                    label: styles.label,
                    checkbox: styles.checkbox,
                    count: styles.count,
                  }}
                />
              </div>
            </div>
          ))}
        </div>
        <AccessibleButton
          className={styles.applyFiltersBtn}
          onClick={onClose}
          disabled={refinementCount <= 0}>
          {refinementCount > 0
            ? refinementCount === 1
              ? t('numFilterApplied')
              : t('numFiltersApplied', [refinementCount.toString()])
            : t('noFilters')}
        </AccessibleButton>
      </div>
    );
  },
);

FacetFilters.displayName = 'FacetFilters';
export default FacetFilters;
