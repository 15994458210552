import { useRouter } from 'next/router';
import styles from './searchSuggestions.module.scss';
import buildSearchResultLink from './helpers/utils';

/**
 * @param {object} props component props
 * @param {string} props.popularSearchTermsTitle popular search terms title
 * @param {Array} props.popularSearchTerms popular search terms
 * @param {string} props.locale locale for navigation
 * @param {string} props.slug page slug for navigation
 * @param {Function} props.closeSearch function to close search
 * @returns {JSX.Element} react component
 */
export default function SearchSuggestions({
  popularSearchTermsTitle,
  popularSearchTerms,
  locale,
  slug,
  closeSearch,
}) {
  const router = useRouter();

  const handleSuggestionClick = (suggestion) => {
    if (suggestion.trim() && slug) {
      const searchResultLink = buildSearchResultLink(locale, slug, suggestion);
      router.push(searchResultLink);
      closeSearch();
    }
  };

  const SearchSuggestion = ({ suggestion }) => (
    <li className={styles.suggestion}>
      <button
        className={styles.suggestionButton}
        type="button"
        onClick={() => handleSuggestionClick(suggestion)}>
        {suggestion}
      </button>
    </li>
  );

  return (
    <div className={styles.suggestions}>
      <div className={styles.suggestionsWrapper}>
        <h2 className={styles.header}>{popularSearchTermsTitle}</h2>
        <ul>
          {popularSearchTerms.map((suggestion, i) => (
            <SearchSuggestion key={i} suggestion={suggestion} />
          ))}
        </ul>
      </div>
    </div>
  );
}
