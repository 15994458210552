import styles from './relatedProductsNow.module.scss';
import cx from 'classnames';
import AccessibleButton from '../AccessibleButton/AccessibleButton';
import NowProductCard from './ProductCards/NowProductCard';

/**
 * @param {object} [props] component props
 * @param {object} [props.fields] related products data
 * @param {object} [props.fields.cta] cta object
 * @param {string} [props.fields.description] description text
 * @param {string} [props.fields.eyebrow] eyebrow text
 * @param {string} [props.fields.title] title text
 * @param {object} [props.fields.products] data for each product
 * @param {string} [props.className] additional class name for the section element
 * @returns {JSX.Element} now fresh related products component
 */
export default function RelatedProductsNow({
  fields: { cta, description, eyebrow, title, products },
  className,
}) {
  return (
    <section className={cx(styles.container, className)}>
      <div className={styles.topWrapper}>
        <div>
          {eyebrow && <p className={styles.eyebrow}>{eyebrow}</p>}
          <h2 className={styles.title}>{title}</h2>
        </div>
        {description && <p className={styles.description}>{description}</p>}
        {cta?.fields && (
          <div className={styles['prod-cta']}>
            <AccessibleButton type="primary" theme="dark" link={cta}>
              {cta.fields.linkText}
            </AccessibleButton>
          </div>
        )}
      </div>
      {products?.map((product, index) => (
        <NowProductCard key={index} product={product} index={index} />
      ))}
    </section>
  );
}
